<template>
  <div class="main-content">
    <div class="section post-section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img">
                    <figure v-if="$store.state.article.big_img_news.length">
                      <img
                        :src="$store.state.article.big_img_news[$store.state.article.big_img_news.length - 1].get_img.media_server_url + $store.state.article.big_img_news[$store.state.article.big_img_news.length - 1].get_img.url"
                        :alt="$store.state.article.title" class="adaptive"
                        style="height:582px;width:100%;object-fit:cover"/>
                    </figure>
                    <figure v-else>
                      <img :src="$store.state.system_data.base_url + '/img/placeholder.png'"
                           :alt="$store.state.article.title" class="adaptive"
                           style="height:582px;width:100%;object-fit:cover"/>
                    </figure>
                    <div class="post-main-overlay">
                      <div class="post-main-data">
                        <h2 class="title-21">{{ $store.state.article.title }}</h2>
                        <div class="meta-tp-5">
                          <div class="date-tp-4"><i class="li_clock"></i>{{
                              $store.state.article.updated_at | human_date
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-html="$store.state.article.description"></div>

                  <div class="col-row">
                    <template v-for="(img, i) in $store.state.article.big_img_news">
                      <article class="post post-tp-5 col-one-third"
                               v-if="i !== $store.state.article.big_img_news.length - 1" :key="i">
                        <figure>
                          <a href="#" @click.prevent="fullImage(i)">
                            <img
                              :src="img.get_img.media_server_url + img.get_img.url"
                              height="173"
                              width="255"
                              :alt="$store.state.article.title"
                              class="adaptive"
                              style="height: 200px; width: 95%; object-fit: contain;"
                            >
                          </a>
                        </figure>
                      </article>
                    </template>
                  </div>

                  <div v-if="$store.state.article.get_documents.length"
                       style="display:grid;grid-gap: 8px; margin: 5px 0">
                    <template v-for="(link, i) in $store.state.article.get_documents">
                      <a :href="link.document.media_server_url + link.document.url" v-if="link.document">
                        {{ link.document.original_name || link.document.url }}
                      </a>
                    </template>
                  </div>

                  <div class="ya-share2-wrapper">
                    <p>Поделиться: </p>
                    <yandex-share :services="['vkontakte','facebook','odnoklassniki','twitter','whatsapp']"/>
                  </div>

                  <div class="gallery-modal" v-if="$store.state.article.big_img_news.length && show_modal">
                    <div @click="closeModal()" class="gallery-modal-background" title="Нажмите чтобы закрыть"></div>
                    <div class="gallery-modal-wrapper">
                      <div class="gallery-modal-image-container">
                        <img :alt="$store.state.article.title" :src="modal_content.image"
                             :title="$store.state.article.title" class="gallery-modal-image">
                        <div class="gallery-modal-controls bvi-hide">
                          <button @click="closeModal()" @touch="closeModal()" class="gallery-modal-close"
                                  title="Нажмите чтобы закрыть" type="button">
                            <svg fill="none" height="20" viewBox="0 0 21 20" width="21"
                                 xmlns="http://www.w3.org/2000/svg">
                              <rect fill="white" height="25.6198"
                                    transform="matrix(0.701403 -0.712765 0.701403 0.712765 0.320312 1.73913)"
                                    width="2.43998"></rect>
                              <rect fill="white" height="25.6198"
                                    transform="matrix(-0.701403 -0.712765 -0.701403 0.712765 19.6816 1.73913)"
                                    width="2.43998"></rect>
                            </svg>
                          </button>
                          <button @click="nextImage(0)" class="gallery-modal-controls-button left-button"
                                  title="Предыдущий элемент" type="button"></button>
                          <button @click="nextImage(1)" class="gallery-modal-controls-button right-button"
                                  title="Следующий элемент" type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YandexShare from '@cookieseater/vue-yandex-share';

export default {
  name: 'Article',
  async asyncData(context) {
    await context.store.dispatch('newsPage', context.route.params.id);
  },
  metaInfo() {
    return this.$seo(
      this.$store.state.article.title
    );
  },
  components: {YandexShare},
  data() {
    return {
      show_modal: false,
      modal_content: {
        image: '',
        active: 0,
        count: 0,
        base: ''
      }
    }
  },
  beforeMount() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 37) {
        this.nextImage(0);
      } else if (e.keyCode === 39) {
        this.nextImage(1);
      } else if (e.keyCode === 27) {
        this.closeModal();
      }
    });
  },
  methods: {
    /**
     * Переключение изображений в открытой галереи фотографий
     * @param direction - направление переключения
     */
    nextImage(direction) {
      let img = this.$store.state.article.big_img_news;
      if (direction === 0) {
        this.modal_content.active--;
        if (img[this.modal_content.active] === undefined) {
          this.modal_content.active = this.$store.state.article.big_img_news.length - 1;
        }
      } else {
        this.modal_content.active++;
        if (img[this.modal_content.active] === undefined) {
          this.modal_content.active = 0;
        }
      }
      this.modal_content.image = img[this.modal_content.active].get_img.media_server_url + '/' + img[this.modal_content.active].get_img.url;
    },
    /**
     * Вывод модалки с изображением
     * @param i - id изображения на которое кликнули
     */
    fullImage(i) {
      this.show_modal = true;
      this.modal_content.active = i;
      this.modal_content.image = this.$store.state.article.big_img_news[i].get_img.media_server_url + '/' + this.$store.state.article.big_img_news[i].get_img.url;
      this.checkShowModal();
    },
    closeModal() {
      this.show_modal = false;
      this.checkShowModal();
    },
    checkShowModal() {
      document.querySelector('.header-tp-4').style.zIndex = this.show_modal ? '1' : '2';
    }
  },
};
</script>
